<template>
  <div>
    <el-dialog
        title="富文本配置"
        :visible.sync="dialogVisible"
        @close="handleClose"
        :close-on-click-modal="false">
        <el-button type="warning" size="mini" @click="openAddImg">图片上传</el-button>
        &nbsp;&nbsp;&nbsp;
        <el-button type="success" size="mini" @click="openLook">预览页面</el-button>
        <br/><br/>
        <div id="wangEditor" class="wangEditor"></div>
    </el-dialog>
    <el-dialog
        title="预览"
        :visible.sync="dialogVisibleLook"
        width="600px"
        @close="handleLookClose"
        :handleClose-on-click-modal="false"
        class="look-dialog">
      <div class="look-one">
        <div class="look" id="look" v-html="data"></div>
      </div>
      <div class="operating">
          <el-button type="primary" size="medium" 
            @click="submitDate">
            保存
          </el-button>
          <el-button size="medium" @click="handleLookClose()">
            取消
          </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="上传图片"
      :visible.sync="dialogVisibleAddImg"
      width="800px"
      @close="handleAddImgClose"
      :close-on-click-modal="false"
      style="height:500px">
      <el-form ref="form" :model="editParam" label-width="300px">
        <div class="form-flex-box">
          <el-form-item label="" prop="cover">
            <el-upload class="upload-demo" action="#" :http-request="updateFile">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-image
              v-if="editParam.cover"
              style="height: 100px"
              :src="setImage(editParam.cover)"
              :preview-src-list="[setImage(editParam.cover)]">
            </el-image>
          </el-form-item>
        </div>
        图片地址: <span>{{ editParam.cover ? setImage(editParam.cover) : null }}</span>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

// 引入 wangEditor
import wangEditor from 'wangeditor'
import API from "@/api";
import baseConfig from "@/utils/serverUrlConfig";

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibleLook: false,
      dialogVisibleAddImg: false,
      editor: null,
      editorData: '',
      id: '',
      rich_text: '',
      data: "",
      video: null, //视频对象
      // 封面上传地址
      action: baseConfig.baseServerUrl + "/upload/image",
      editParam: {
        imgUrl: baseConfig.imgUrl,
        cover: "",
      },
    }
  },
  methods: {
    createEditor() {
      const editor = new wangEditor(`#wangEditor`)
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.editorData = newHtml
      }
      // 创建编辑器
      editor.create()
      if (this.rich_text != '' && this.rich_text != ""){
        editor.txt.html(this.rich_text);
      }
      this.editor = editor
    },
    // 打开编辑视频弹窗、传递需要编辑的参数
    open(video) {
      this.dialogVisible = true
      this.video = video
      this.id = video.id
      if(video.rich_text != "") {
        this.rich_text = video.rich_text.replace(/<img src="/g,"<img src=\"https://yuan.dhuqh.com/")
      }
      this.$nextTick(() => {
        this.createEditor();
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.editor.destroy()
      this.editor = null
      this.cleanData()
    },
    // 关闭预览弹窗
    handleLookClose() {
      this.dialogVisibleLook = false
      this.data = ""
    },
    handleAddImgClose() {
      this.dialogVisibleAddImg = false
      this.data = ""
      this.editParam = {
        imgUrl: baseConfig.imgUrl,
        cover: "",
      }
    },
    openAddImg() {
      this.dialogVisibleAddImg = true
    },
    cleanData() {
      this.id = ""
      this.rich_text = ""
      this.video = null
    },
    openLook() {
      this.dialogVisibleLook = true
      this.data = this.editor.txt.html()
    },
    // 保存
    submitDate() {
      if (this.data == null || this.data==""){
          alert("保存内容为空")
          return
      }
      if (this.video == null || this.video == ""){
          alert("保存内容异常")
          return
      }
      if (this.id == null || this.id == "") {
        alert("编辑帖子id异常！")
        return
      }
      //去掉源站地址
      var rich_text = this.data.replace(/https:\/\/yuan.dhuqh.com\//g,"")
      //编辑
      const params = this.video
      params.id = this.id
      params.rich_text = rich_text
      API.updateVideoDatav(params)
        .then((res) => {
          if (res.code === 0) {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.$emit("handlerLoadList");
          } else {
            this.$message.error("保存失败！");
          }
          this.handleLookClose();
        })
        .catch(() => {
          this.handleLookClose();
        });
    },
    async updateFile(file) {
      const form = new FormData();
      form.append("upload", file.file);
      const { data } = await API.fileUpload(form);
      this.editParam.cover = data.coverImg;
    },
    setImage(val) {
      // 判断是否拥有域名 如果有 去掉域名 字符串第一位是'/'
      if (val.indexOf("http") === 0) {
        val = "/" + val.split("/").slice(3).join("/");
      } else if (val.indexOf("/") !== 0) {
        val = "/" + val;
      }
      // 根据去掉域名后的数据进行匹配
      let strValue = "";
      let data = JSON.parse(JSON.parse(localStorage.getItem("imageUrl")));
      for (const key in data) {
        if (key.indexOf("/") !== 0 && key !== "*") {
          //如果匹配键名前面不带'/'
          if (val.indexOf("/" + key + "/") === 0) {
            //
            strValue = data[key];
          }
        } else if (key.indexOf("/") === 0 && key !== "*") {
          //带'/'
          if (val.indexOf(key + "/") === 0) {
            //有匹配的
            strValue = data[key];
          }
        }
      }
      if (strValue) {
        return strValue + val;
      } else {
        return data["*"] + val;
      }
    },
  }
}
</script>

<style lang="scss">
  .home {
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
    .btn {
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px 10px;
      cursor: pointer;
    }
    .btn1 {
      padding: 5px 10px;
    }
    h3 {
      margin: 30px 0 15px;
    }
    .w-e-text-container{
      height: 700px !important;
    }
    .wangEditor{
      z-index: 1 !important;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
  .el-dialog {
    height: 950px;
    width: 900px;
  }
  .w-e-text-container {
    height: 700px !important;
  }
  .look-one{
    border: 2px solid; color: #121327;
    border-radius: 12px;
    width: 500px;
    margin-left: 30px;
    overflow: auto;
    height: 800px;
  }
  .look{
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: 414px;
    p {
      width: 414px;
      img {
        width: 414px;
      }
    }
  }
  .operating{
    text-align:center;
    margin-top: 15px;
  }
  .form-flex-box {
    display: flex;
    .el-form-item {
      flex: 1;
    }
    .item-box {
      display: flex;
      flex-direction: column;
    }
  }
  .el-form-item__content {
    margin-left: 250px !important;
  }
</style>
